import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';

const NikkeGuidesAde: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Ade guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_ade.jpg"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Ade guide & review</h1>
          <h2>A guide & review for Ade in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>17/02/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="ade" enablePopover />
        </div>
        <p>
          After a whole year since her first introduction, Ade: the Perfect Maid
          is finally here! Elegance personified is the best way to describe her.
          You can watch her dance with light sticks in the minigame, but her
          dance skills do not match up to her performance. Her kit provides an
          interesting "debuff shield" from an incoming debuff, or removes an
          already existing debuff. She also gives small ATK buffs and a Max HP
          buff that does not heal. Will she be able to live up the hype, or will
          we have the shortest review ever? Spoiler: We will!
        </p>
        <SectionHeader title="Kit Analysis" />
        <p>
          Ade's kit revolves around the ability to protect your teammates from
          filthy debuffs, by both cleansing and preventing them at the same
          time. In addition, she provides a little ATK buff longside a decent
          “empty” HP bar buff that can be filled in order to provide extra
          defensive capability.
        </p>
        <h6>Skill 1</h6>
        <blockquote>
          <p>■ Activates when entering battle. Affects all allies.</p>
          <p>
            Perfect Maid: Gain debuff immunity to 1 debuff(s) and stacks up to 1
            times(s) continuously.
          </p>
          <p>■ Activates when own HP falls below 90%. Affects all allies.</p>
          <p>ATK ▲ 5.19% of caster's ATK for 5 sec.</p>
        </blockquote>
        <p>This skill has 2 parts:</p>
        <p>
          All teammates will gain a stack of Debuff Immunity which removes an
          existing debuff (but will not remove CP penalty and special debuffs
          like Suppression/Stun Rope of “Sentry”…) and prevents the next
          incoming debuff if it is not consumed yet. In Nikke, debuffs are
          currently not an issue to require specific methods dealing with. Some
          debuffs can be nasty, like Blanc’s burst in pvp, ATK reduction from
          some Raptures/Bosses like TailBone and that’s where Ade might shine.
          One advantage of prepared debuff removal is that you don't have to
          delay or wait for the next burst rotation.
        </p>
        <StaticImage
          src="../../../images/nikke/generic/ade_guide.jpg"
          alt="Ade"
        />
        <p>
          This is how you'll be looking at Tail Bone when Ade renders it
          useless!
        </p>
        <p>
          The other part is an ATK buff. Though you can maintain this buff like
          100% of the time by keeping her HP hovering up/down 90%, the effect is
          definitely not worth the pain. Assuming Ade has the same core / gears
          / level / bond affections with your Attacker, her ATK stat will be
          83.33% of your DPS, which translates this S1’s effect into 4.3% ATK up
          for an Attacker. The value is underwhelming, but it can be combined
          with her other skills to make a decent value.
        </p>
        <p>
          The Debuff Immunity does not work like a “Vaccine”. It will not make
          you “immune” to a certain debuff for the whole stage, but rather it
          acts as a “Shield” that blocks the next upcoming debuff or removes an
          already existing debuff. Note that debuff blocking may harm you
          instead, for example Sanis without Ammo Reduction, or A2 without HP
          reduction (her burst effect will not trigger at all if this debuff is
          removed!).
        </p>
        <Alert variant="primary">
          <p>
            Since Nikke treats timing of skills in this order: Self skill &gt;
            External skill, hence Debuff Immunity will mostly consume your own
            debuff first before it can block any harmful external Debuff.
            Essentially, whichever debuff was applied first will be removed by
            Ade.
          </p>
          <p>
            Once Ade has &lt; 90% HP, she needs to be &gt; 90% HP to retrigger
            the ATK buff (in her skill 1) with the help of her S2 or Burst Skill
            (both of which reduce her HP below 90%, and bring it back up after
            the buff expires). If she has already been under 90% max HP and is
            not healed back to &gt; 90%, she will <strong>NEVER</strong> trigger
            S1 attack buff.
          </p>
        </Alert>
        <p>
          <strong className="red">
            DO NOT RUN ADE WITH S.ANIS OR A2. THESE NIKKES NEED THEIR SPECIFIC
            DEBUFFS TO PERFORM WELL AND DEAL DAMAGE.
          </strong>
        </p>
        <h6>Skill 2</h6>
        <blockquote>
          <p> ■ Activates after 420 normal attack(s). Affects all allies.</p>
          <p>
            Perfect Maid: Gain debuff immunity to 1 debuff(s) and stacks up for
            1 time continuously.
          </p>
          <p>■ Activates after 120 normal attack(s). Affects all allies.</p>
          <p>
            Max HP ▲ 15.62% of caster's Max HP without restoring HP, lasts for 5
            sec.
          </p>
        </blockquote>
        <p>
          With this skill, Ade can keep providing debuff protection for her
          team, which will come in handy under some situations. That sounds
          reasonable on paper, but let’s convert those effects into time-based
          intervals.
        </p>
        <ul>
          <li>
            Assuming your device can run at 60/30 FPS, her first effect which
            requires 420 normal attacks will take 35~42s shooting relentlessly!
            And you do not even have that much ammo to begin with. With 60 ammo
            per magazine, she will have to reload 7 times at max to even trigger
            this effect. Since she has 1.5s reload, that adds up to total time,
            making them 45.5~52.5s. Putting it in practice, you may proc this
            effect 2~3 times every 90s fight, or 4 ~ 6 every boss fight
            (180s).This niche limits her ability in team building by a lot,
            since you may only want her in a boss fight to make this skill worth
            her slot.
          </li>
          <li>
            The second part effect will trigger every 13~15s (including 2 times
            reloading), putting herself at 100%/115.62% (level 10 value) ~ 84%
            Max Hp for 5s. During this time, you can “fill” the gap by healing
            Ade and her allies, which gives them more survivability. And if you
            notice, this skill is kinda synchronized with her S1, because it
            forces Ade dipping below 90% to proc the ATK buff. Make sure to
            bring a healer or taunter with Ade because if she is hit and her HP
            falls below 90% permanently, she will never be able to activate her
            skill 1 again.
          </li>
        </ul>
        <h6>Burst</h6>
        <blockquote>
          <p> ■ Affects all allies.</p>
          <p>
            Max HP ▲ 25.15% of caster's Max HP without restoring HP, lasts for
            10 sec.
          </p>
          <p>ATK ▲ 10.15% of caster's ATK for 10 sec.</p>
        </blockquote>
        <p>
          Just like Mast’s burst, Ade also “boosts” the HP bars of her allies by
          an amount without healing them, putting them at 79.9% / 71% Max HP
          (With S2 effect too). This may help some Nikkes that are required to
          stay at low HP as fast as possible, for example Guillotine, Scarlet.
          Healers will also benefit from this effect because all of the current
          healing effect (beside life steal) scales on Max HP =&gt; more Max HP,
          more heal. Last but not least, 2B will also like this effect because
          she’s currently the only damage dealer scaling on HP.
        </p>
        <p>
          This burst also has a moderate ATK buff, which can be converted into
          8.46% ATK buff for your Attacker(s). At the very peak, your
          Attacker(s) may enjoy 12% ATK buff from Ade alone. It looks small, but
          the effect lasts 10s, and comparing her with other B2 excluding Naga
          and Blanc, she suddenly does not look so bad anymore (20s cd - B2 -
          ATK buffer)
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage" />
        <p>
          Ade serves a very niche role, just like other cleansers currently in
          game - cleansing debuff, which comes with an inherent problem. Not
          many battles require their presence. And, let’s hope it stays this
          way, because from Shift Up’s previous games, they did create a lot of
          stages that had nasty debuffs! Ade is an alternative to XMica or
          Cocoa. Her debuff cleansing is team wide and does not require any
          skill activation the first time around. Thus, while not being able to
          trigger it at will is a drawback by itself, you are not forced to use
          her Burst Skill at all, allowing you to use stronger B2 skills.
        </p>
        <p>
          Ade's MAX HP buffs also enhance healers if their effectiveness is
          based on their current MAX HP! So pairing a healer with her will also
          be a viable option to boost the amount of healing your team receives!
        </p>
        <h6>Campaign</h6>
        <p>
          Ade will not be used much here. She may be helpful in stages that
          feature Tailbone (ATK reduction). Just like XMica, Ade will have her
          place if you fight against Sunbather, which inflicts -60% ATK
          reduction debuffs!
        </p>
        <h6>Union Raid</h6>
        <p>
          Situational against bosses that apply major debuffs, but you'd be
          better off running a more versatile B2.
        </p>
        <h6>Solo Raid</h6>
        <p>
          With the current meta teams, it’s very hard to find a spot for Ade.
          Everything she does, others can do and they do better. Unless Shift Up
          really forces a boss that constantly debuffs your team with debuffs,
          otherwise you may not want to put Ade in your team at all. The closest
          we can come up is using Ade as a replacement for Mast… if you use Mast
          at all.
        </p>
        <h6>PVP</h6>
        <p>
          She can, and will block Blanc’s burst, rendering her useless. Sadly,
          nowadays, people tend to pair Blanc with Jackal, and Jackal debuff
          will eat Ade’s debuff immunity first. In this case, do not run Ade!
          XMica should perform better than Ade for PvP by a good margin. Ade has
          a better burst gen than normal AR, similar to Snow White/Mihara's.
          However, if you look at the burst gen chart, you will realize that
          they are all considered “slow” there. Ade will not shake PVP meta.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          Ade is a pure supporter, which means she does not scale really well
          with OL. If you want to OL her, go for the Ammo lines, because they
          reduce the time she needs to trigger her S2 by reducing reload
          intervals. For other options, you can choose whatever between
          Elemental, Hit Rate, Crit Rate… because it does not matter.
        </p>
        <h5>Skill Investments</h5>
        <ul>
          <li>
            <strong>Skill 1: 1</strong>
          </li>
          <ul>
            <li>
              This skill gains abysmal value each level. With 0.21% Caster ATK
              gained per level, we highly recommend you not leveling it at all.
              Not to mention, if she’s damaged below 90% and not healed back
              more than 90%, she won’t be able to trigger S1 again, ever!
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 5</strong>
          </li>
          <ul>
            <li>
              Mathematically, only after level 5, her S2 will leave Ade below
              90% Max Hp, triggering her S1 condition to buff ATK. Since every
              level only gives her 0.72% Max HP which is very small, you do not
              want to spend your hard earned skill material on this skill beyond
              level 5.
            </li>
          </ul>
          <li>
            <strong>Burst: 1</strong>
          </li>
          <ul>
            <li>
              Burst always leaves Ade below 90% HP, so she can always trigger
              her S1 during burst. Problem is, each level only gives you 0.4%
              caster ATK more, and burst material is very scarce, you need to
              think twice before investing in Ade.
            </li>
          </ul>
        </ul>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <p>
          Both Bastion and Resilience would serve her well for PvE purposes for
          more reliable Reset Time (Skill 2) stacking. Bastion &gt; Resilience
          for Ade. Don’t run Vigor on her since it inflates Ade HP and makes it
          more difficult to dip her HP below 90%.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1: Totem cleanser</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="naga" enablePopover />
              <NikkeCharacter mode="icon" slug="marciana" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="ade" enablePopover />
            </div>
          </div>
        </div>
        <p>
          In this team, you will use Ade as a flex slot non burst. You just need
          her Debuff Immunity to protect you from certain nasty debuffs without
          sacrificing Burst 1 like Cocoa or Mica. Clearly not this Rapture! Use
          your usual B3s that you would use with this team, like Red Hood,
          Scarlet or her alter version, Modernia, etc. Naga/Marciana will heal
          her back up to full HP if she ever drops below 90%.
        </p>
        <h5>Team #2: Guillotine flex team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="moran" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sakura" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="guillotine" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="ade" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          This team is built solely for Guillotine, because she requires her HP
          to be as low as possible, and she needs to do it as fast as possible
          too. Normally B2 spot will be reserved for Mast, but if you do not
          have Mast, or Mast is used on another team, then you can use Ade here
          instead. This team has nice DPS, decent CDR (Sakura S2 level needs to
          be as high as possible) and a power to protect you from debuff. Make
          sure that Moran pulls damage away from both Ade and Guillotine,
          otherwise Ade will fail to provide her ATK buff if her HP falls below
          90%!
        </p>
        <h5>Team #3: 2B team</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="noise" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="ade" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="2b" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="sakura" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          2B is the only DPS that scales on HP, so she benefits from Ade as
          expected. This is a variation of the Guillo team above because you
          want Noise here instead. Noise gives a lot of Max HP to 2B which
          further enhances 2B’s damage, while healing the team - something
          Guillo does not want. Same with the above team, Mast is oftenly put
          here but if she’s occupied or not available, then Ade is not a bad
          choice.
        </p>
        <SectionHeader title="Should You Pull" />
        <p>
          <strong className="red">NO!</strong> But if you really simp for Ade,
          the latest maid of Maid for you, then one copy does not hurt. Ade is
          an interesting Nikke - She works well without investment, and she does
          not gain much by investing in her. Maybe you can pull one copy for
          future proof, or put her in the wishlist instead. By future proof, we
          mean that she may become important when new bosses with debuffs are
          released. You can always rely on Cocoa and XMica for that, though.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Has cleanse - a rare mechanic to have in Nikke.</li>
                  <li>
                    Her debuff shield has high uptime, and can be replenished
                    after a while.
                  </li>
                  <li>
                    Does not require high investments to reach her potential.
                  </li>
                  <li>So elegant!</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Niche usage, not much content in Nikke needs cleanse.</li>
                  <li>
                    Compared to other B2s, her performance is extremely
                    underwhelming and hence will not see use in Meta teams.
                  </li>
                  <li>
                    She requires a healer to heal her back if she is damaged, or
                    she will lose S1 Attack Buff forever.
                  </li>
                  <li>
                    Debuff shield takes too long to replenish (40-50 seconds).
                  </li>
                  <li>Her kit does not do much.</li>
                  <li>
                    Her scaling is terrible, which leaves you not investing in
                    her, at all!
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box c">
            <h6>C</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box b">
            <h6>B</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>PVP</p>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesAde;

export const Head: React.FC = () => (
  <Seo
    title="Ade guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Ade in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
